.people {
    background-color: rgba(255, 255, 255, 1);
    padding: 1rem 0;
    background-image: url("../../assets1/logos/Ellipse\ 5.svg");
    background-repeat: no-repeat;
    background-size: 35rem 70.5625rem;
}

.peopleHeader {
    font-size: 2rem;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: -0.04rem;
    color: rgba(29, 30, 74, 1);
    border-bottom: 4px solid rgba(204, 85, 1, 1);
    padding: 0rem var(--spacing-2, 1rem) var(--spacing-1, 0.5rem) var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.sliderCardMain {
    position: relative;
    display: flex;
    padding: 0;
}

.sliderCard {
  display: flex;
  margin-top: 10rem;
  margin-bottom: 5rem;
  padding: 6rem 8.9375rem;
  align-items: flex-start;
  align-self: stretch;
  background: var(--Background-White, #fff);
  box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06),
    0px 1px 10px 0px rgba(16, 24, 40, 0.1);
  background-image: url("../../assets1/logos/left_quote.svg");
  background-repeat: no-repeat;
  background-size: 15.01581rem;
  background-position: 90% center;
  position: relative;
}

.sliderImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  width: 10rem;
  height: 10rem;
  padding: 0.625rem;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06),
    0px 1px 10px 0px rgba(16, 24, 40, 0.1);
  top: -18%;
  right: 70%;
}

.sSlider {
  margin-top: 2rem;
}

.sliderImage img {
  width: 10rem;
  width: 10rem;
  border-radius: 100%;
}

.sliderBtn {
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.75rem;
  background: #cc5501;
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}

.prev {
  position: absolute;
  left: -1.5625rem;
  bottom: 38%;
}

.next {
  position: absolute;
  right: -1.5625rem;
  bottom: 38%;
}

.sliderName span {
  color: var(--Text-Text-Primary);
  font-family: Raleway;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.875rem */
  letter-spacing: -0.025rem;
}

.sliderProfession span {
  color: var(--Text-Text-Tertiary, #5d6676);
  font-family: Raleway;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.02rem;
}

.sliderDescription {
  text-align: left;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-top: -0.8rem;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #cc5501;
}

@media (max-width: 576px) {
    .peopleHeader {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .people {
      background-image: none;
    }
    .sliderImage {
        top: -30%;
        right: 20%;
    }
    .sSlider {
      margin-top: 1rem;
    }
    .sliderCard {
      margin-top: 5rem;
      padding: 0px;
      padding: var(--spacing-12, 2rem) 4rem;
    }
    .sliderIndicator {
      text-align: center;
    }
    .sliderProfession {
      font-size: 10px;
    }
    .sliderDescription {
      font-size: 16px;
    }
    .sliderName {
      font-size: 20px;
      margin-top: 3rem;
    }
    .sliderCard {
      background-size: 5rem;
    }

}

@media (min-width: 374px) and (max-width: 376px) {
  .emma, .yakub, .john, .grace, .joseph {
    top: -10%;
  }
  .comfort, .kola, .anita, .sufiyan {
    top: -15%;
  }
  .ese, .igoje {
    top: -17%;
  }
}
@media (min-width: 413px) and (max-width: 415px) {
  .sliderImage {
    right: 25%;
  }
  .emma, .yakub, .john, .grace, .joseph {
    top: -23%;
  }
  .ese, .kola, .igoje {
    top: -21%;
  }
  .emma, .anita, .yakub, .sufiyan, .john, .grace, .comfort {
    top: -16%;
  }
  .joseph {
    top: -12%;
  }
}
@media (min-width: 389px) and (max-width: 391px) {
  .sliderImage {
    right: 25%;
  }
  .emma, .yakub, .john, .grace, .joseph {
    top: -12%;
  }
  .ese, .kola, .anita {
    top: -20%;
  }
  .sufiyan, .igoje, .comfort {
    top: -17%;
  }
}
@media (min-width: 429px) and (max-width: 431px) {
  .sliderImage {
    right: 25%;
  }
  .emma, .yakub, .john, .grace, .joseph, .sufiyan {
    top: -14%;
  }
  .ese, .kola, .anita, .igoje, .comfort {
    top: -19%;
  }
}
@media (min-width: 392px) and (max-width: 394px) {
  .sliderImage {
    right: 25%;
  }
  .emma, .john, .grace, .sufiyan {
    top: -14%;
  }
  .ese, .oc, .kola {
    top: -20%;
  }
  .anita, .igoje, .comfort {
    top: -17%;
  }
  .yakub, .joseph {
    top: -12%;
  }
}
@media (min-width: 411px) and (max-width: 413px) {
  .sliderImage {
    right: 25%;
  }
  .emma, .john, .grace, .sufiyan {
    top: -14%;
  }
  .ese, .oc, .kola {
    top: -20%;
  }
  .anita, .igoje, .comfort {
    top: -17%;
  }
  .yakub, .joseph {
    top: -12%;
  }
}
@media (min-width: 359px) and (max-width: 361px) {
  .sliderImage {
    right: 20%;
  }
  .emma, .john, .grace, .sufiyan {
    top: -10%;
  }
  .oc {
    top: -20%;
  }
  .ese, .kola {
    top: -18%;
  }
  .anita, .igoje, .comfort {
    top: -15%;
  }
  .yakub, .joseph {
    top: -8%;
  }
}
@media (min-width: 319px) and (max-width: 321px) {
  .sliderImage {
    right: 15%;
    top: -25%;
  }
  .kola {
    top: -20%;
  }
  .emma, .anita, .sufiyan, .john, .grace, .igoje {
    top: -10%;
  }
  .comfort {
    top: -14%;
  }
  .joseph, .yakub {
    top: -7%;
  }
  .ese, .kola {
    top: -14%;
  }
  .pelu, .lanre, .oc {
    top: -17%;
  }
  .peopleHeader {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
}
}
@media (min-width: 539px) and (max-width: 541px) {
  .sliderImage {
    right: 50%;
  }
  .joseph, .grace, .john, .sufiyan, .yakub, .emma {
    top: -20%;
  }
}
@media (min-width: 279px) and (max-width: 281px) {
  .sliderImage {
    right: 10%;
    top: -17%;
  }  .peopleHeader {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }
  .joseph {
    top: -6%;
  }
  .comfort, .kola {
    top: -14%;
    right: 18%;
  }
  .grace, .igoje, .anita {
    top: -12%;
  }
  .john, .sufiyan {
    top: -10%;
  }
  .yakub, .emma {
    top: -8%;
  }
}