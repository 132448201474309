.footer {
    background-color: rgba(9, 25, 87, 1);
    padding: 40px 0 20px 0px;
}

a .footer_link {
    color: white;
    text-decoration: none;
}

a.footer_link span {
    width: 2rem;
    padding: 0.5rem 0.6rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 2rem;
    background: #2B9A9C;
    color: white;
    transition: 0.5s ease;
}

a.footer_link:hover span {
    width: 2rem;
    padding: 0.5rem 0.6rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 2rem;
    background: transparent;
    border: 1px solid #2B9A9C;
    color: #2B9A9C;
}

.usefulLink {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 1.875rem */
    letter-spacing: -0.025rem;
    color: white;
    text-transform: capitalize;
}

.backToTop a span{
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #3EDAD8;
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}

.backToTop:hover a span{
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: transparent;
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    border: 1px solid #3EDAD8;
}

.backToTop_text span {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
    color: rgba(62, 218, 216, 1);
}

.terms a {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
    color: white;
    text-transform: capitalize;
}

.terms a {
    text-decoration: none;
}

.terms a:visited {
    color: (internal value);
    text-decoration: underline;
    cursor: auto;
}
  
.terms a:link:active, a:visited:active {
    color: (internal value);
}

.courtesy {
    font-weight: 400;
    color: white;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
    font-size: 0.875rem;
    font-style: normal;
    text-transform: capitalize;
}

.footer-social-share {
    margin-top: 35px;
}