.majorHero {
    background: rgb(255,255,255);
    position: relative;
    /*background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);*/
}

.headerShapedback {
    position: absolute;
    width: 45.4375rem;
    height: 38.3rem;
    flex-shrink: 0;
    background: linear-gradient(235deg, #31CED2 -56.59%, #FFF 63.34%);
    clip-path: ellipse(62% 60% at 100% 50%);
    z-index: 1;
    right: 0;
}

.blur {
    filter: blur(5px);
}

.headerMobShapedback {
    display: none;
}

.headerMobShapedback {
    display: none;
}

.manifestoBack {
    position: absolute;
    right: 0;
    top: 27%;
}

.feat {
    z-index: 555;
}

.brandsBack {
    position: absolute;
    left: -30rem;
    top: 34.7%;
    width: 37.75rem;
    height: 37.375rem;
    flex-shrink: 0;
    border-radius: 37.75rem;
    border: 2px dashed black;
    z-index: 0;
}

.brandsback1 {
    display: none;
}

@media (max-width: 576px) {
    .manifestoBack {
        display: none;
    }
    .headerShapedback {
        display: none;
    }
    .headerMobShapedback {
        display: block;
        position: absolute;
        top: 0%;
        right: 0;
        z-index: 00;
    }
    .brandsBack {
        top: 29.74%;
        width: 20.625rem;
        height: 20.5rem;
        border-radius: 20.625rem;
        left: -10rem;
    }
    .brandsback1 {
        display: block;
        position: absolute;
        top: 31.3%;
    }
}