.heroText {
    color: #091957;
    font-family: Raleway;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: -1.12px;
    z-index: 555;
}

.additions {
    width: 100%;
    height: 5rem;
}

.heroImage {
    z-index: 555;
}

@media (max-width: 576px) {
    .heroText {
        font-family: Mulish;
        font-size: 36px;
        font-style: normal;
        font-weight: 1000;
        line-height: 120%;
        letter-spacing: -0.72px;
        z-index: 555;
    }
    .additions {
        width: 100%;
        height: 4rem;
    }
}