.brands {
    background-color: #FCF6F5;
    padding: 1rem 0;
    z-index: 2;
}

.brandsText {
    color: #161B23;
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.02rem;
    z-index: 2;
}

#brandsText {
    z-index: 4;
}

#brandsHeader {
    z-index: 4;
}

.brandsHeader {
    font-size: 2rem;
    font-weight: 900;
    font-style: normal;
    line-height: 120%;
    letter-spacing: -0.04rem;
    color: #161B23;
    border-bottom: 4px solid #ECA239;
    padding: 0rem var(--spacing-2, 1rem) var(--spacing-1, 0.5rem) var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    z-index: 2;
}

.brandUI {
    position: relative;
    cursor: pointer;
}

.learnMore, .learnMore1, .learnMore2, .learnMore3 {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    opacity: 0;
}

.brandUI:hover img {
    opacity: 0; /* Hide the image on hover */
}

.brandUI img {
    object-fit: cover; /* Preserve aspect ratio while covering the container */
    transition: opacity ease; /* Add a smooth transition for opacity */
}

.brandUI:hover .learnMore {
    display: flex; /* Show the container on hover */
    opacity: 1;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 162, 57, 1);
    transition: opacity 3s ease;
}

.brandUI:hover .learnMore1 {
    display: flex; /* Show the container on hover */
    opacity: 1;
    justify-content: center;
    align-items: center;
    background-color: #CC5501;
    transition: opacity 3s ease;
}

.brandUI:hover .learnMore2 {
    display: flex; /* Show the container on hover */
    opacity: 1;
    justify-content: center;
    align-items: center;
    background-color: #091957;
    transition: opacity 3s ease;
}

.brandUI:hover .learnMore3 {
    display: flex; /* Show the container on hover */
    opacity: 1;
    justify-content: center;
    align-items: center;
    background-color: #AA1859;
    transition: opacity 3s ease;
}

.learnBtn {
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.32px;
}

.animate__animated.animate__slideInUp {
    --animate-duration: 1s;
}

.modalBdy {
    color: #161B23;
    font-family: Raleway;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.02rem;
}

@media (max-width: 576px) {
    .brandsHeader {
        font-size: 24px;
        font-weight: 800;
        font-style: normal;
    }
    .brands {
        background-image: url('../../assets1/logos/Ellipse\ 5.svg');
        background-repeat: no-repeat;
        background-position: left bottom;
    }
}