.services {
    background-color: white;
    padding: 40px;
}

.serviceHeader {
    color: rgba(22, 27, 35, 1) !important;
    font-size: 2rem;
    font-family: 'Raleway';
    font-weight: 800;
    border-bottom: 4px solid #3EDAD8;
    padding: 0rem var(--spacing-2, 1rem) var(--spacing-1, 0.5rem) var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.serviceTitle h5 {
    font-weight: 800;
    font-size: 1.5rem;
    color: #1d1e4a;
}

.serviceTitle p {
    color: #161B23;
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.cardBack {
    background-color: white;
    box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06),
    0px 1px 10px 0px rgba(16, 24, 40, 0.1);
    border-radius: 20px;
    border: none;
    padding-top: 1rem;
    cursor: context-menu;
}

.card1:hover {
    background-color: #ffd400;
}

.card2:hover {
    background-color: #5ac5c9;
}

.card3:hover {
    background-color: #FF5C35;
}

@media (max-width: 360px) {
    .serviceHeader {
        font-size: 1.5rem;
    }
}