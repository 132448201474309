@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* reset */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.5;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
input, button, textarea, select {
  font: inherit;
}
picture, video, canvas {
  display: block;
  max-width: 100%;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}