.featured {
    background-color: #2BAAAD;
    padding: 1rem 0;
}

.featuredHeader {
    font-size: 2rem;
    font-weight: 900;
    font-style: normal;
    line-height: 120%;
    color: white;
    letter-spacing: -0.04rem;
    text-transform: uppercase;
    border-bottom: 4px solid #1A1B43;
    padding: 0rem var(--spacing-2, 1rem) var(--spacing-1, 0.5rem) var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.featuredinImages img {
    animation: scrollImage 30s infinite linear;
}

.featuredinImages {
    overflow: hidden;
    white-space: nowrap;
}

@keyframes scrollImage {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
}