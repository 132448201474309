.manifesto {
    background-color: #FCF6F5;
    color: #ebf9fa;
    padding: 40px 0;
    z-index: 7;
}

.manifestoHeader {
    color: rgba(22, 27, 35, 1) !important;
    font-size: 2rem;
    font-family: 'Raleway';
    font-weight: 800;
    border-bottom: 4px solid #3EDAD8;
    padding: 0rem var(--spacing-2, 1rem) var(--spacing-1, 0.5rem) var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.manifesto_text {
    color: #161B23;
    font-size: 1rem;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.02rem;
}

@media (max-width: 576px) {
    .manifestoHeader {
        font-size: 24px;
        font-weight: 800;
    }
}