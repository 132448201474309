.partInput:focus {
  outline: 2px;
}

.PhoneInputInput input {
  border: none;
  color: #252d3c !important;
  font-weight: 500;
}

.partCon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  /* width: 45rem; */
  padding: auto;
}

.partTitle h1 {
  color: #161b23;
  font-family: "Montserrat";
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 2.4rem */
  letter-spacing: -0.04rem;
}

.partSuccesMess {
  color: #5d6676;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.0225rem;
  font-family: "Montserrat";
}

.backBtn button {
  display: flex;
  padding: 0.34375rem 0.5rem 0.34375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: none;
  border-radius: 2rem;
  font-family: "Montserrat";
  color: #b9b9c7;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

.backBtn button:hover {
  color: #252d3c;
  border-radius: 2rem;
  border: none;
  background: rgba(156, 168, 188, 0.242);
  display: flex;
  padding: 0.34375rem 1rem 0.34375rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.partBtn {
  display: flex;
  padding: 0.6rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-family: "Montserrat";
  border: none;
  border-radius: 0.25rem;
  background: #5bc5c9;
  color: #1d1e4a;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
  float: right;
}

.partBtnScss {
  display: flex;
  width: 50%;
  padding: 0.6rem 1.5rem;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  gap: 0.25rem;
  border-radius: 0.25rem;
  background: #5bc5c9;
  color: #1d1e4a;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
  float: right;
}

.partInput {
  display: flex;
  height: 3.3125rem;
  width: 100%;
  padding: 1rem;
  font-family: "Montserrat";
  align-items: center;
  align-self: stretch;
  justify-content: center;
  flex: auto;
  border: none;
  border-bottom: 0.6px solid #c0c4ca;
  color: #252d3c;
}

.partInput::placeholder {
  color: #5d6676;
  font-size: 0.875rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

.partTitle h1 span {
  color: rgba(9, 25, 87, 1);
}

.partTitle p {
  display: flex;
  color: #161b23;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.02rem;
  width: 100%;
  font-family: "Montserrat";
}

.partLabel p {
  color: #161b23;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.02rem;
}

.partLabel div span {
  color: #e8342e;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Montserrat";
  font-style: normal;
}
.partInput[type] {
  color: #161b23;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

.errorInput {
  border-bottom: 1px solid #ff9d83 !important;
}

.errorText {
  color: #ff3932;
  margin-top: 5px;
  font-size: 12px;
  font-family: "Montserrat";
}

.error {
  color: #ff3932;
  margin-top: 5px;
  font-size: 1rem;
  font-family: "Montserrat";
}

@media (max-width: 568px) {
  .portCon {
    width: 23.4375rem;
  }
  .partBtn {
    width: 100%;
  }
  .partBtnScss {
    width: 100%;
  }
  .partLabel div span {
    margin-top: 1rem;
  }
}
