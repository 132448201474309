:root {
  --spacing-4: 1.5rem;
  --Radius-sm: 0.25rem;
}
.navLink {
  margin-right: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #091957;
}

.navLinkScrolled {
  color: #ffffff !important;
}

.navLink .arrowUp {
  font-size: 1rem;
  margin-right: 0.2rem;
}

.navLink:hover {
  color: #091957;
}

.navLinkBtn {
  display: flex;
  margin-top: -0.5rem;
  width: 7rem;
  padding: 0.7rem 0 0.7rem 0;
  justify-content: center;
  align-items: center;
  border-radius: var(--Radius-sm);
  background: #091957;
  color: white;
  font-size: 0.75rem;
  letter-spacing: -0.015rem;
  font-weight: 600;
}

.navLinkBtnScrolled {
  background: #ffffff !important;
  color: #091957 !important;
}

.navLinkBtn .arrowUp {
  font-size: 1rem;
  margin-right: 0.2rem;
}

.navLinkBtn:hover {
  display: flex;
  width: 7rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #091957;
  border-radius: 0.25rem;
  background: transparent;
  color: #091957;
  font-size: 0.75rem;
  letter-spacing: -0.015rem;
  font-weight: 600;
}

.navLinkBtn:hover path {
  stroke: #091957;
}

.face {
  margin-left: 1rem;
}

.navLink .arrowUp {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease;
}

.arrowUp {
  margin: 0;
  padding: 0;
}

.navLink:hover .arrowUp {
  visibility: visible;
  opacity: 1;
}

.navBtn {
  color: #091957;
  cursor: pointer;
}

.navBtnScrolled {
  color: #ffffff !important;
}

.navBtn:hover {
  color: #091957;
}

.scrolled {
  background-color: #091957;
}

@media (max-width: 576px) {
  .navLink {
    color: white;
  }
  .navCon {
    position: relative;
  }

  .navColl {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .open {
    background-color: #42678e;
    width: 100%;
  }
  .open1 {
    background-color: #42678e;
    width: 100%;
    padding: 3rem;
    margin: 0 auto;
  }
  .navCon {
    position: sticky;
  }
}
