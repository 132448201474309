* {
  font-family: "Montserrat";
}

.custom-select-container {
    position: relative; /* Adjust the width as needed */
    display: flex;
    width: 100%;
    height: 3.3125rem;
    padding: var(--spacing-2, 1rem);
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.6px solid var(--Borders-Stroke, #C0C4CA);
    flex: 1 0 0;
  }
  
  .select-header {
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--Text-Text-Tertiary, #5D6676);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
  }
  
  .select-header.open {
    background-color: #f0f0f0;
  }
  
  .icon {
    transition: transform 0.3s;
    margin-left: 1rem;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .icon.rotate {
    transform: rotate(180deg);
  }
  
  .options-container {
    position: absolute;
    top: 120%;
    left: 0;
    max-height: 150px; /* Adjust the max-height as needed */
    width: 100%;
    overflow-y: auto;
    border: 1px solid #fff;
    border-top: none;
    background-color: #fff;
    color: var(--Text-Text-Tertiary, #5D6676);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
    box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06), 0px 1px 10px 0px rgba(16, 24, 40, 0.10);
    z-index: 99999;
  }
  
  .option {
    padding: 10px;
    display: flex;
    transition: background-color 0.3s;
  }

  .selected-option {
    color: #161B23;
  }
  
  .option:hover {
    color: #161B23;
  }
  
  .selected {
    color: #161B23;
    background: var(--Background-Light-Bg, #EBECEE);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .word {
    color: var(--Text-Text-Tertiary, #5D6676);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
  }
  